import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/1.jpg';
// import p2 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/2.jpg';
// import p3 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/3.jpg';
// import p4 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/4.jpg';
// import p5 from 'assests/Photos/Clubs/ScienceClub/2024/GeneralCCA/5.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/Literaryclub2023/components';
import { LiterarySidebar } from 'views/Literaryclub2023/components';
import Sidebar2024 from '../ScienceClub/Sidebar2024';
import Scienceclubsidebar2023 from '../ScienceClub/Scienceclubsidebar2023';
import ScienceClubSideBar from '../ScienceClubSideBar';

const Modelpresentation2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_url}/home/events-activities/association/ScienceClub/Evscca/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/ScienceClub/Evscca/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/ScienceClub/Evscca/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/ScienceClub/Evscca/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/association/ScienceClub/Evscca/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/association/ScienceClub/Evscca/6.webp`;
  

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 2.2,
            cols: 1,
        },
       
        {
            src: p3,
            source: p3,
            rows: 2.2,
            cols: 1,
        }, 
        {
            src: p4,
            source: p4,
            rows: 2,
            cols: 1,
        },

        {
            src: p6,
            source: p6,
            rows: 2,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1.3,
            cols: 2,
        },
        {
            src: p5,
            source: p5,
            rows: 1.3,
            cols: 2,
        }
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                Class 1 & 2: Model Presentation (speak few sentences on the model- Means of Communication)

                                </Typography>
                                <br></br>
                                {/* <Typography variant='h7' align='justify' >
                                Class 3: Vegan power,
                                Class 4: Plant Power Saves the World,
                                Class 5: Significance of spices in Indian Cuisine  
                                </Typography> */}
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 1 & 2    Date:  06 September 2024 

                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The EVS CCA Activity for Classes 1 and 2 was held on Friday, 06 September 2024, which exhibited the creativity, artistic and speaking skills of the participants. It was an individual performance wherein the participants presented a model based on ‘Means of Communication’ and expressed that communication plays an important role in our daily lives, to the audience.
                                <br></br>
                                The students combined their creative and oratorical skills to put up a wonderful presentation. It gave an insight of the ability to grasp the information with exposure to the appropriateness of using the means of communication with advanced technology. Overall the event was a grand success.
                                <br></br>
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Communication is lifeline of any relationship.”
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                                    <Sidebar2024/>
                        </Box>
                        <Box marginBottom={4}>
                            <ScienceClubSideBar/>
                            
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default Modelpresentation2024;